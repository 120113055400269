<template>
  <div class="page-main-box pt-24 pr-16" id="company">
    <div class="d-flex mb-10 company_info pl-24">
      <div>当前查询企业： {{ companyDetail.name }}</div>
      <div class="mx-30">统一信用代码： {{ companyDetail.tax_number }}</div>
      <div>
        账户类型：
        {{ companyDetail.type === "通用账户" ? "-" : companyDetail.type }}
      </div>
    </div>
    <!-- 搜索头 -->
    <search-header :schemaData="schemaList" @getDataList="handleFormSearch" />
    <!-- 列表 -->
    <div class="pl-16" :style="{ height: tableHeight + 'px' }">
      <p-table
        :dataSource="tableDataSource"
        id="service-configuration-table"
        @sort-change="sortChangeEvent"
        height="auto"
      >
        <p-t-column type="seq" min-width="60" title="序号" fixed="left" />
        <p-t-column min-width="166" title="卡号" field="card_no" />
        <p-t-column min-width="136" title="收支方向" field="direction">
          <template v-slot="{ row }">
            {{ row.direction && (row.direction === "OUT" ? "消费" : "充值") }}
          </template>
        </p-t-column>
        <p-t-column
          min-width="140"
          title="金额（元）"
          field="money"
          align="right"
        >
          <template v-slot="{ row }">
            {{ row.money && (row.money / 100).toFixed(2) }}
          </template>
        </p-t-column>
        <p-t-column
          min-width="140"
          title="操作时间"
          field="operating_time"
          sortable
        >
          <template v-slot="{ row }">
            {{
              row.operating_time &&
                formatToDateTime(row.operating_time, "yyyy-MM-dd")
            }}
          </template>
        </p-t-column>
        <p-t-column width="80" title="操作" fixed="right">
          <template v-slot="{ row }">
            <a-button
              type="link"
              @click="handleGoDetail(row)"
              v-if="
                (row.direction === 'IN' &&
                  $store.getters.roles.includes('RechargeWithDraw')) ||
                  (row.direction === 'OUT' &&
                    $store.getters.roles.includes('ServiceOrder'))
              "
              >订单明细</a-button
            >
          </template>
        </p-t-column>

        <template #footer>
          <a-button class="mt-12 ml-32" @click="exportList" type="primary"
            >导出</a-button
          >
        </template>
      </p-table>
    </div>
  </div>
</template>

<script>
import { reactive, toRaw, ref, onMounted } from "vue";
import { Table } from "@wlhy-web-components/common";
import { schemaList } from "./config";
import useSearchForm from "@/hooks/UseSearchForm";
import { useDailyBillApi } from "@/apis/customer";
import { useDailyBillExportApi } from "@/apis/financial";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import { useEnumAccountDirectionApi } from "@/apis/enums";
import useSearch from "@/hooks/useSearch";
import $await from "@/utils/await";
import { useRoute, useRouter } from "vue-router";
import useTableHeight from "@/hooks/useTableHeight";

import { exportExcel } from "@/hooks/useExport";
export default {
  name: "enterprise-detail",
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const dailyBillApi = useDailyBillApi();
    const dailyBillExportApi = useDailyBillExportApi();
    const enumAccountDirectionApi = useEnumAccountDirectionApi();

    // table高度
    const tableHeight = ref();

    const routeInfo = route.query;
    const companyDetail = ref({
      name: routeInfo?.name,
      company_id: routeInfo?.company_id,
      tax_number: routeInfo?.tax_number,
      account_id: routeInfo?.account_id,
      type: routeInfo?.type
    });

    let schemaListAll = schemaList;

    (async () => {
      let [, account] = await $await(enumAccountDirectionApi());
      schemaListAll = useSearch(schemaList, account, "direction");
    })();

    const { filter, handleFormSearch } = useSearchForm(schemaListAll);
    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const refresh = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const { company_id, tax_number, account_id } = toRaw(
          companyDetail.value
        );
        const data = {
          ...toRaw(filter.value),
          company_id,
          tax_number,
          account_id,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await dailyBillApi(data);
        return { records: res.list, total: res.total };
      }
    });

    const exportList = async () => {
      const { company_id, tax_number, account_id, name } = toRaw(
        companyDetail.value
      );
      const data = {
        company_name: name,
        company_id,
        tax_number,
        account_id,
        ...toRaw(filter.value)
      };
      let res = await dailyBillExportApi(data);
      exportExcel(res, "账户明细");
    };

    const sortChangeEvent = colums => {
      const { order } = colums;
      filter.value.sort = "operating_time " + order;
      refresh();
    };

    const handleGoDetail = row => {
      const { company_id } = toRaw(companyDetail.value);
      if (row.direction === "IN") {
        router.push({
          path: "/financial/recharge-withdraw",
          query: { time: row.operating_time, id: company_id }
        });
      } else {
        router.push({
          path: "/order/service-order",
          query: { time: row.operating_time, id: row.account_id }
        });
      }
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableDataSource,
      schemaList,
      handleFormSearch,
      exportList,
      formatToDateTime,
      sortChangeEvent,
      handleGoDetail,
      companyDetail,
      tableHeight
    };
  }
};
</script>

<style lang="less" scoped>
#company {
  position: relative;
  overflow: hidden;
}
:deep(.search-header .operator-left) {
  margin-left: 0;
}
</style>
