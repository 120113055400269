/**
 * 搜索头
 */
export const schemaList = [
  {
    type: "select",
    field: "direction",
    label: "收支方向",
    iw: 160,
    defaultValue: null,
    options: []
  },
  {
    field: ["start_time", "end_time"],
    iw: 324,
    label: "操作时间",
    type: "dateRange",
    props: {
      placeholder: ["开始时间", "结束时间"],
      showTime: true,
      format: "YYYY/MM/DD"
    }
  }
];
